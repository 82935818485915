// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-profile-profile-tsx": () => import("./../../../src/components/profile/Profile.tsx" /* webpackChunkName: "component---src-components-profile-profile-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-apec-challenges-tsx": () => import("./../../../src/pages/apec-challenges.tsx" /* webpackChunkName: "component---src-pages-apec-challenges-tsx" */),
  "component---src-pages-auth-tsx": () => import("./../../../src/pages/auth.tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-members-tsx": () => import("./../../../src/pages/members.tsx" /* webpackChunkName: "component---src-pages-members-tsx" */)
}

