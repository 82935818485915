import "firebase/analytics";
import firebase from "firebase/app";

const isProd = process.env.GATSBY_FIREBASE_ENV === "production";

interface FirebaseConfig {
  apiKey: string | undefined;
  authDomain: string | undefined;
  projectId: string | undefined;
  storageBucket: string | undefined;
  messagingSenderId: string | undefined;
  appId: string | undefined;
  measurementId?: string | undefined;
}

let firebaseConfig: FirebaseConfig = {
  apiKey: isProd
    ? process.env.GATSBY_PROD_API_KEY
    : process.env.GATSBY_DEV_API_KEY,
  authDomain: isProd
    ? process.env.GATSBY_PROD_AUTH_DOMAIN
    : process.env.GATSBY_DEV_AUTH_DOMAIN,
  projectId: isProd
    ? process.env.GATSBY_PROD_PROJECT_ID
    : process.env.GATSBY_DEV_PROJECT_ID,
  storageBucket: isProd
    ? process.env.GATSBY_PROD_STORAGE_BUCKET
    : process.env.GATSBY_DEV_STORAGE_BUCKET,
  messagingSenderId: isProd
    ? process.env.GATSBY_PROD_SENDER_ID
    : process.env.GATSBY_DEV_SENDER_ID,
  appId: isProd
    ? process.env.GATSBY_PROD_APP_ID
    : process.env.GATSBY_DEV_APP_ID,
};

firebase.initializeApp(firebaseConfig);

if (isProd) {
  firebaseConfig["measurementId"] = process.env.GATSBY_PROD_MEASUREMENT_ID;
  firebase.analytics();
}

export default firebase;
